import Vue from 'vue'
import VueRouter from 'vue-router'
import OnlineGames from '../views/OnlineGames.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'onlinegames',
        component: OnlineGames
    },
    {
        path: '/chgkplan',
        name: 'chgkplan',
        component: () => import('../views/Chgkplan.vue')
    },
    {
        path: '/platforms',
        name: 'platforms',
        component: () => import('../views/Platforms.vue')
    },
    {
        path: '/mediyka',
        name: 'mediyka',
        component: () => import('../views/Games.vue')
    },
    {
        path: '/games',
        name: 'games',
        component: () => import('../views/Games.vue')
    },
    {
        path: '/links',
        name: 'links',
        component: () => import('../views/Links.vue')
    },
    {
        path: '/news',
        name: 'news',
        component: () => import('../views/News.vue')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('../views/About.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
