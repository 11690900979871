<template>
  <span>
    <small class="mt-0 pt-0 float-right text-muted">{{ text }}</small>
    <hr class="mt-3 mb-3"/>
  </span>
</template>

<script>
export default {
  props: ['text']
}
</script>
