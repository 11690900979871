export default class UrlUtils {
    static getUrlName(url) {
        if (url.includes("vk.com")) {
            if (url.includes("?w=")) {
                return 'Ссылка на vk post';
            }
            if (url.includes("/topic-")) {
                return 'Ссылка на vk topic';
            }
            return 'Ссылка на vk';
        }
        if (url.includes("facebook.com")) {
            if (url.includes("/events/")) {
                return 'Ссылка на fb event';
            }
            return 'Ссылка на fb';
        }
        if (url.includes("quizy.pro/streams/")) {
            return 'Стрим на quizy'
        }
        if (url.includes("youtube.com")) {
            return 'Ссылка на youtube';
        }
        if (url.includes("instagram.com")) {
            return 'Ссылка на instagram';
        }
        if (url.includes("docs.google.com")) {
            return 'Ссылка на google docs';
        }
        if (url.includes(".t.me")
            || url.includes("//t.me")
            || url.includes("ttttt.me")) {
            return 'Ссылка на telegram';
        }
        if (url.includes("twitch.tv")) {
            return 'Ссылка на twitch';
        }
        return 'Ссылка на ' + this.extractHostname(url);
    }

    static extractHostname(url) {
        var hostname;
        //find & remove protocol (http, ftp, etc.) and get hostname
        if (url.indexOf("//") > -1) {
            hostname = url.split('/')[2];
        } else {
            hostname = url.split('/')[0];
        }

        //find & remove port number
        hostname = hostname.split(':')[0];
        //find & remove "?"
        hostname = hostname.split('?')[0];

        return hostname.startsWith('www.') ? hostname.substring(4) : hostname;
    }
}
