<template>
  <b-media class="small" style="min-height: 80px; line-height: 1.2;">
    <template #aside>
      <b-img width="60" :src="'/api/data/games-logos/' + item.image"/>
    </template>

    <div class="game-name pb-1">
      {{ item.time.format("HH:mm") }}
      <span class="pl-3">{{ item.name }}</span>
    </div>
    <span class="text-muted mt-0 pt-0">
      {{ item.org }} | {{ item.time.format("D MMM") }}
      <span v-for="(p,pi) in item.price" :key="pi"> | {{ p }}</span>
    </span>
    <br/>
    <span>
      <b-badge v-if="item.chgk" variant="warning" class="mr-1">ЧГК</b-badge>
      <b-badge v-if="item.free" variant="success" class="mr-1">Бесплатно</b-badge>
      <b-badge v-if="item.donate" variant="success" class="mr-1">За донаты</b-badge>
      <b-badge v-if="item.lang" variant="warning" class="mr-1">{{ item.lang }}</b-badge>
      <b-badge v-if="item.noreg" variant="secondary" class="mr-1">Нет регистрации</b-badge>
      <span class="text-muted" v-for="(u,ui) in item.urls" :key="ui">
        <a :href="u" target="_blank" class="mr-1"
           :class="{'font-weight-bold': ui == 0}">{{ getUrlName(u) }}</a>
        <span v-if="ui != Object.keys(item.urls).length - 1">| </span>
        </span>
    </span>
  </b-media>
</template>

<style>
.game-name {
  font-size: 20px;
}
</style>

<script>
import UrlUtils from '../utils/url-utils';

export default {
  props: ['item'],
  methods: {
    getUrlName: function (url) {
      return UrlUtils.getUrlName(url);
    }
  }
}
</script>
