<template>
  <b-container>
    <b-row>
      <b-col class="text-center text-secondary small">
        Найдено игр: {{ allGames.length }} (из них {{ chgkCount }} - ЧГК, и {{ freeCount }} - бесплатные).
      </b-col>
    </b-row>
    <b-row>
      <b-col class="text-center mb-3">
        <b-button-group size="sm">
          <b-button :pressed.sync="filter.type == 'all'" @click="filterData('all')"
                    :variant="filter.type == 'all' ? 'primary' : ''">
            Показать все игры
          </b-button>
          <b-button :pressed.sync="filter.type == 'chgk'" @click="filterData('chgk')"
                    :variant="filter.type == 'chgk' ? 'primary' : ''">
            Показать только ЧГК
          </b-button>
          <b-button :pressed.sync="filter.type == 'free'" @click="filterData('free')"
                    :variant="filter.type == 'free' ? 'primary' : ''">
            Показать только бесплатные
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>
    <div class="pt-5 d-flex justify-content-center" v-if="loading">
      <b-spinner variant="primary" label="Загружаем данные..."></b-spinner>
      <div class="ml-3 align-self-center">Загружаем данные...</div>
    </div>
    <b-row>
      <b-col>
        <b-container fluid id="scrollspy-nested">
          <b-row v-for="g in games" :key="g.id + '_' + render" :id="g.id">
            <b-col>
              <GameGroup v-if="g.type === 'gr'" :text="g.val" :count="g.count"></GameGroup>
              <GameSubGroup v-if="g.type === 'sgr'" :text="g.val"></GameSubGroup>
              <Game v-if="g.type === 'gm'" :item="g.val"></Game>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import moment from "moment";
import Game from "../components/Game";
import GameGroup from "../components/GameGroup";
import GameSubGroup from "../components/GameSubGroup";

export default {
  components: {GameSubGroup, GameGroup, Game},
  data: function () {
    return {
      loading: false,
      render: 1,
      filter: {
        type: 'all'
      },
      groups: [],
      games: [],
      allGames: [],
      chgkCount: 0,
      freeCount: 0
    }
  },
  mounted() {
    this.loading = true;
    let url = "/api/games.php";
    return Promise.all([url].map(url => fetch(url).then(r => r.json())))
        .then(r => {
          this.initializeAllGames(r[0]);
          this.calculateData();
          this.loading = false;
        });
  },
  methods: {
    filterData(type) {
      this.filter.type = type;
      this.calculateData();
    },
    initializeAllGames(games) {
      const now = moment();
      games.forEach(game => {
        game.time = moment(game.time);
        if (moment.duration(now.diff(game.time)).asHours() < game.duration) {
          if (game.chgk) {
            this.chgkCount++;
          }
          if (game.free) {
            this.freeCount++;
          }
          this.allGames.push(game);
        }
      });
      this.allGames.sort((a, b) => a.time - b.time);
    },
    calculateData() {
      this.render++;
      this.groups = [];
      this.games = [];
      const groupData = {};
      this.allGames.forEach(game => {
        if (this.isValid(game)) {
          this.addToGroup(game, groupData);
        }
      });
      Object.values(groupData).forEach(group => group.itemOrder = Object.keys(group.items).sort());
      Object.keys(groupData).sort().forEach(groupKey => this.fillData(groupData[groupKey]));
    },
    isValid(game) {
      return this.filter.type === 'all'
          || this.filter.type === 'chgk' && game.chgk
          || this.filter.type === 'free' && game.free;
    },
    addToGroup(game, groupData) {
      const groupKey = this.addGroup(game.time, groupData);
      const subgroupKey = this.addSubGroup(game.time, groupKey, groupData);
      groupData[groupKey].items[subgroupKey].games.push(game);
    },
    addGroup(time, groupData) {
      const groupKey = "g" + time.format("YYYYMMDD");
      if (groupData[groupKey]) {
        return groupKey;
      }
      const valueShort = time.format("ddd, DD.MM");
      const value = time.format("dddd, DD.MM");
      groupData[groupKey] = {
        key: groupKey,
        value: value.charAt(0).toUpperCase() + value.slice(1),
        valueShort: valueShort.charAt(0).toUpperCase() + valueShort.slice(1),
        itemOrder: [],
        items: {}
      }
      return groupKey;
    },
    addSubGroup(time, groupKey, groupData) {
      const hours = this.getGoupHour(time.format("HH"));
      const subgroupKey = groupKey + "s" + hours[0] + hours[1];
      if (groupData[groupKey].items[subgroupKey]) {
        return subgroupKey;
      }
      groupData[groupKey].items[subgroupKey] = {
        key: subgroupKey,
        value: hours[0] + "-" + hours[1],
        text: hours[0] + ":00-" + hours[1] + ":00",
        games: []
      }
      return subgroupKey;
    },
    getGoupHour(hour) {
      if (hour < 12) return ["00", "12"];
      if (hour < 18) return ["12", "18"];
      if (hour < 20) return ["18", "20"];
      return ["20", "00"];
    },
    fillData(group) {
      const navGroup = {id: group.key, val: group.valueShort, sub: []};
      let listGroup = {type: "gr", id: group.key, val: group.value, count: 0};
      this.groups.push(navGroup);
      this.games.push(listGroup);
      let gameId = 0;
      group.itemOrder.forEach(itemKey => {
        const item = group.items[itemKey];
        listGroup.count += item.games.length;
        navGroup.sub.push({id: itemKey, val: item.value});
        this.games.push({type: "sgr", id: itemKey, val: item.text});
        item.games.forEach(game => {
          gameId++;
          this.games.push({type: "gm", id: group.key + gameId, val: game});
        });
      });
    }
  }
}
</script>
