import Vue from 'vue'
import {BootstrapVue, IconsPlugin, VBScrollspyPlugin} from 'bootstrap-vue'
import App from './App.vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import router from './router'

Vue.config.productionTip = false

Vue.use(VBScrollspyPlugin)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.mixin({
    methods: {
        declination: function (n, n1, n2, n5, n11) {
            if (n % 100 > 10 && n % 100 < 20) {
                return n11;
            }
            if (n % 10 == 1) {
                return n1;
            }
            if (n % 10 > 1 && n % 10 < 5) {
                return n2;
            }
            return n5;
        }
    }
})

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')


