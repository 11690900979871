<template>
  <div>
    <b-navbar sticky toggleable="md" type="dark" variant="dark">
      <b-navbar-brand href="#" class="logo">QUIZA</b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item to="/games" :active='$route.name =="games"'>Игры</b-nav-item>
          <b-nav-item to="/" :active='$route.name =="onlinegames"'>Онлайны</b-nav-item>
          <b-nav-item to="/chgkplan" :active='$route.name =="chgkplan"'>Планировщик ЧГК</b-nav-item>
          <b-nav-item to="/platforms" :active='$route.name =="platforms"'>Площадки</b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item to="/links" :active='$route.name =="links"'>Ссылки</b-nav-item>
          <b-nav-item to="/news" :active='$route.name =="news"'>Новости</b-nav-item>
          <b-nav-item to="/about" :active='$route.name =="about"'>О сайте</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-col class="small text-muted text-right pt-1 pr-2 pb-2">{{ this.now }}</b-col>
    <b-container>
      <b-row>
        <b-col class="small">
          <b-alert v-if="msg.html" :variant="msg.variant" v-html="msg.html" show></b-alert>
        </b-col>
      </b-row>
    </b-container>
    <router-view/>
  </div>
</template>

<style>
.logo {
  color: #B109BA !important;
}
</style>

<script>
import moment from "moment";

moment.locale('ru');

export default {
  data: function () {
    return {
      msg: {},
      now: moment().format("Сейчас HH:mm, DD.MM.YYYY (ddd). Временная зона: Z")
    }
  },
  created() {
    let msgUrl = "/api/data/message.json?" + new Date().getTime();
    const promises = [msgUrl].map(url => fetch(url).then(r => r.json()));
    return Promise.all(promises).then(r => {
      this.msg = r[0];
    });
  },
}
</script>
