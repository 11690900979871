<template>
    <div class="mt-4">
      <b class="h3">{{ text }}</b>&nbsp;
      <b-badge small variant="secondary">Игр: {{ count }}</b-badge>
    </div>
</template>

<script>
export default {
  props: ['text', 'count']
}
</script>
